import React, { useContext, useEffect, useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageBackgroundColor } from "../components/context/page-background-color";

// Components
import { PageSEO } from "../components/seo/page-seo";
import { BiographyNavigation } from "../components/biography/biography-navigation";

const Page = styled.div`
  & h1 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.05em;

    margin: 0 0 30px 0;

    text-indent: 99px;

    @media (max-width: 768px) {
      text-indent: 35px;
    }
  }

  & p {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.05em;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  & section {
    &.catalogues {
      margin: 23px 0 0 0;

      @media (max-width: 768px) {
        margin: 0;
      }
    }
  }

  @media (max-width: 768px) {
    margin: 0 0 55px 0;
  }
`;

const Grid = styled.div`
  & .body-text {
    max-width: 870px;
    margin: 0 0 40px 0;

    & p {
      &:first-of-type {
        margin-top: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  & .year {
    text-indent: 99px;

    @media (max-width: 768px) {
      text-indent: 35px;
    }
  }

  & ol {
    & li {
      margin: 0 0 1em 0;

      &:last-of-type {
        margin: 0;
      }
    }
  }
`;

const Bibliography = ({ data }) => {
  const [pageBackgroundColor, setPageBackgroundColor] = useContext(
    PageBackgroundColor
  );
  const [activeTag, setActiveTag] = useState(`all`);

  useEffect(() => {
    setPageBackgroundColor({
      text: `#163262`,
      background: `#ECE5DF`,
    });
  }, []);

  const catalogues = data.prismicBibliography.data.body.map(
    (content, index) => {
      if (content.slice_type === "bibliography_year") {
        const bibliographyItems = content.items.map((item, index) => (
          <li key={`single_bibliography_item_${index}`}>
            <div
              dangerouslySetInnerHTML={{
                __html: item.bibliography.html,
              }}
            />
          </li>
        ));

        if (bibliographyItems.length >= 1) {
          return (
            <div
              className="body-text"
              key={`single_body_text_${index}_${content.id}`}
            >
              <h4 className="year">{content.primary.bibliography_year}</h4>
              <ol>{bibliographyItems}</ol>
            </div>
          );
        }
      }
    }
  );

  const periodicals = data.prismicBibliography.data.body1.map(
    (content, index) => {
      if (content.slice_type === "bibliography_year") {
        const bibliographyItems = content.items.map((item, index) => (
          <li key={`single_bibliography_item_${index}`}>
            <div
              dangerouslySetInnerHTML={{
                __html: item.bibliography.html,
              }}
            />
          </li>
        ));

        if (bibliographyItems.length >= 1) {
          return (
            <div
              className="body-text"
              key={`single_body_text_${index}_${content.id}`}
            >
              <h4 className="year">{content.primary.bibliography_year}</h4>
              <ol>{bibliographyItems}</ol>
            </div>
          );
        }
      }
    }
  );

  return (
    <>
      <PageSEO
        title={data.prismicBibliography.data.title.text}
        description={null}
        image={null}
        url={`https://yinkashonibare.com${data.prismicBibliography.url}`}
      />

      <Page>
        <BiographyNavigation
          bibliographyTags={null}
          activeTag={activeTag}
          setActiveTag={setActiveTag}
          cvTags={false}
        />

        {(activeTag === `all` || activeTag === `catalogues`) && (
          <section className="catalogues" id="catalogues">
            <h1>Exhibition Catalogues & Anthologies</h1>
            <Grid>{catalogues}</Grid>
          </section>
        )}

        {(activeTag === `all` || activeTag === `periodicals`) && (
          <section className="periodicals" id="periodicals">
            <h1>Periodicals</h1>
            <Grid>{periodicals}</Grid>
          </section>
        )}
      </Page>
    </>
  );
};

export default withPreview(Bibliography);

export const query = graphql`
  {
    prismicBibliography {
      url
      data {
        title {
          text
        }
        body {
          ... on PrismicBibliographyBodyBibliographyYear {
            id
            slice_type
            primary {
              bibliography_year
            }
            items {
              bibliography {
                html
              }
            }
          }
        }
        body1 {
          ... on PrismicBibliographyBody1BibliographyYear {
            id
            slice_type
            primary {
              bibliography_year
            }
            items {
              bibliography {
                html
              }
            }
          }
        }
      }
    }
  }
`;
